import { createApp } from "vue";
import App from "./App.vue";
import "./registerServiceWorker";
import store from "./store";
import router from "./router";
import "nprogress/nprogress.css";
import "@fancyapps/ui/dist/fancybox/fancybox.css";
import "@fancyapps/ui/dist/carousel/carousel.css";
import { Fancybox } from "@fancyapps/ui";

/* Font Awesome Integration */
import { library, dom } from "@fortawesome/fontawesome-svg-core";
import {
    faExclamationTriangle as fadExclamationTriangle,
    faPaperPlane as fadPaperPlane,
    faPrint as fadPrint,
    faTrash as fadTrash,
    faArrowRight as fadArrowRight
} from "@fortawesome/pro-duotone-svg-icons";
import {
    faArrowRight as falArrowRight,
    faBookHeart as falBookHeart,
    faHeart as falHeart,
    faMinus as falMinus,
    faPlus as falPlus,
    faSpinner as falSpinner,
    faSpinnerThird as falSpinnerThird,
    faSync as falSync
} from "@fortawesome/pro-light-svg-icons";
import {
    faCircle as farCircle
} from "@fortawesome/pro-regular-svg-icons";
import {
    faArrowLeft as fasArrowLeft,
    faBackspace as fasBackspace,
    faCheck as fasCheck,
    faCircle as fasCircle,
    faExpand as fasExpand,
    faHeart as fasHeart,
    faPrint as fasPrint,
    faShareAlt as fasShareAlt,
    faStar as fasStar
} from "@fortawesome/pro-solid-svg-icons";
import { initial } from "lodash";

library.add(
    fadExclamationTriangle,
    fadPaperPlane,
    fadPrint,
    fadTrash,
    fadArrowRight,
    falArrowRight,
    falBookHeart,
    falHeart,
    falMinus,
    falPlus,
    falSpinner,
    falSpinnerThird,
    falSync,
    farCircle,
    fasArrowLeft,
    fasBackspace,
    fasCheck,
    fasCircle,
    fasExpand,
    fasHeart,
    fasPrint,
    fasShareAlt,
    fasStar
);

Fancybox.bind( "[data-fancybox]", {
    Thumbs: {
        showOnStart: false
    },
    Carousel: {
        slidesPerPage: 1,
        Navigation : false
    }
} );

const app = createApp( App );

app.use( store ).use( router ).mount( "#app" );

// FontAwesome i => svg
dom.watch();
