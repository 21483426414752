<template>
    <div class="with-sidebar">
        <div class="sidebar smaller-header with-scroll" ref="sidebar">
            <a href="#" @click="toggleSidebar()" class="sidebar-toggle"><i></i></a>
            <div class="header">
                <PageHeader :className="slug" :heading="headerHeading">
                    <div class="page-navigation">
                        <router-link
                            class="previous"
                            v-if="totalModels > 1"
                            :to="{
                                name : slug === 'floorplans' ? 'Floorplan' : 'MoveInReadyHome',
                                params: { id: getPreviousModelSlug() }
                            }" />
                        <router-link :to="{ name : slug === 'floorplans' ? 'Floorplans' : 'MoveInReady' }" class="home" />
                        <router-link
                            class="next"
                            v-if="totalModels > 1"
                            :to="{
                                name : slug === 'floorplans' ? 'Floorplan' : 'MoveInReadyHome',
                                params: { id: getNextModelSlug() }
                            }" />
                    </div>
                </PageHeader>

                <div class="floorplan-images" v-if="model.renderings.length" :class="{'of-3': model.renderings.length >= 3 }" @click="openFancyBox($event)">
                    <div class="main-image">
                        <a href="##" data-goto="0" class="image-item">
                            <img :src="model.renderings[0].img" width="200" />
                        </a>
                    </div>
                    <div class="more-images" v-if="model.renderings.length > 2">
                        <a href="##" v-for="n in 2" :key="n" :data-goto="n" class="image-item">
                            <img :src="model.renderings[n].img" width="200" />
                        </a>
                    </div>
                    <a href="##" data-goto="0" class="image-total" v-if="model.renderings.length > 1 && model.renderings.length !== 3">
                        View {{ model.renderings.length }} Images
                    </a>
                </div>

                <a href="#"
                   :data-communityID="communityID"
                   :data-modelID="model.data.modelID"
                   :data-link="model.data.link"
                   data-mapEntryID="0"
                   data-favorite="1"
                   class="favorite"
                   @click.capture="saveFavorite($event)"
                   ref="favoriteLink">
                    <i class="fal fa-heart"></i>
                </a>
            </div>

            <div class="scroll">
                <div class="floorplan-properties">
                    <ul>
                        <li class="bedrooms">
                            <img src="/svg/model-icons/bedrooms.svg" />
                            <span class="title">Bedrooms</span>
                            <span class="value">{{ model.data.bedrooms }}</span>
                            <small v-if="slug === 'floorplans' && model.data.maxbedrooms > model.data.bedrooms">
                                Option for up to {{ model.data.maxbedrooms }}
                            </small>
                        </li>
                        <li class="full-baths">
                            <img src="/svg/model-icons/full-baths.svg" />
                            <span class="title">Full Baths</span>
                            <span class="value">{{ model.data.bathroom }}</span>
                        </li>
                        <li class="half-baths">
                            <img src="/svg/model-icons/half-baths.svg" />
                            <span class="title">Half Baths</span>
                            <span class="value">{{ model.data.half_bath }}</span>
                        </li>
                        <li class="stories">
                            <img src="/svg/model-icons/stories.svg" />
                            <span class="title">Stories</span>
                            <span class="value">{{ model.data.stories }}</span>
                        </li>
                        <li class="garage">
                            <img src="/svg/model-icons/garage.svg" />
                            <span class="title">Garage</span>
                            <span class="value">{{ model.data.garage }}</span>
                        </li>
                        <li class="square-feet">
                            <img src="/svg/model-icons/square-feet.svg" />
                            <span class="title">A/C Square Feet</span>
                            <span class="value">{{ formatNumber(model.data.sqftAC) }}</span>
                            <small>{{ formatNumber(model.data.sqftTotal) }} Total Sq. Ft.</small>
                        </li>
                    </ul>
                </div>

                <div class="floorplan-description" v-if="model.data.description.length" v-html="model.data.description"></div>

                <div class="text-center d-grid px-4 my-3" v-if="hasElevationVisualizer && !model.elevations.length">
                    <button type="button"
                            v-if="hasElevationVisualizer"
                            :class="{'btn-danger':this.elevationVisualizer,'btn-primary':!this.elevationVisualizer }"
                            class="btn rounded-0 p-3 fw-bold text-uppercase shadow-none"
                            @click="openElevationVisualizer($event)">
                        {{ this.elevationVisualizer ? 'Close Visualizer' : 'Select Home Colors' }}
                    </button>
                </div>

                <div class="floorplan-elevations" v-if="model.elevations.length">
                    <div class="d-flex justify-content-between align-items-center mb-2">
                        <h3>Exteriors</h3>
                        <button type="button"
                                v-if="hasElevationVisualizer"
                                :class="{'btn-danger':this.elevationVisualizer,'btn-primary':!this.elevationVisualizer }"
                                class="btn rounded-0 fw-bold text-uppercase shadow-none"
                                @click="openElevationVisualizer($event)">
                            {{ this.elevationVisualizer ? 'Close Visualizer' : 'Select Home Colors' }}
                        </button>
                    </div>
                    <div ref="elevations" class="slider"></div>
                </div>
            </div>
        </div>

        <div class="floorplan-detail">
            <div v-if="showIframe" class="iframe-holder">
                <div class="iframe-loading" ref="iFrameLoader">
                    <div>
                        <i class="fal fa-spinner fa-spin"></i><br />
                        Loading Interactive Floorplan
                    </div>
                </div>
                <iframe :src="model.data.interactiveFP" @load="iFrameLoaded()" v-show="!this.elevationVisualizer"></iframe>
                <iframe :src="model.data.elevationVisualizer" v-if="hasElevationVisualizer" v-show="this.elevationVisualizer" class="elevation-visualizer"></iframe>
            </div>
            <div v-if="!showIframe && model.floorplans.length" id="static-floorplans">
                <select v-if="model.floorplans.length > 1" @change="toggleFloorplan($event)">
                    <option v-for="(floorplan,index) in model.floorplans"
                            :value="index"
                            :key="index">
                        {{ floorplan.caption || `Floorplan ${index + 1}` }}
                    </option>
                </select>

                <section>
                    <div v-for="(floorplan,index) in model.floorplans" :key="index" :class="{ 'on':index === currentStaticIndex }">
                        <img :src="floorplan.img" />
                    </div>
                </section>
            </div>
            <div v-if="!showIframe && !model.floorplans.length" class="no-floorplan">
                There is no floorplan available for this home
            </div>
        </div>
    </div>
</template>

<script>
    import PageHeader from "@/components/PageHeader.vue";
    import { Fancybox,Carousel } from "@fancyapps/ui";
    import Favorites from "@/plugins/Favorites.js";

    export default {
        components: {
            PageHeader
        },
        data(){
            return {
                carousel           : null,
                didEnter           : false,
                currentStaticIndex : 0,
                elevationVisualizer: false
            };
        },
        props: {
            slug: {
                type: String,
                required: true,
                default: ""
            },
            id: {
                type: String,
                required: true,
                default: ""
            }
        },
        computed: {
            hasElevationVisualizer(){
                return this.model.data.elevationVisualizer && this.isOnline;
            },
            showIframe(){
                return this.model.data.interactiveFP !== "" && this.isOnline;
            },
            headerHeading(){
                return this.slug === "floorplans" ?
                    `${this.model.data.name}<small>${this.model.data.collectionName}</small>` :
                    `${this.model.data.name}
                            <small>
                                ${this.model.data.collectionName}
                                &bull; ${this.model.data.floorplan}
                                &bull; ${this.model.data.lot}
                            </small>`;
            },
            modelIndex() {
                return this.filteredModels.findIndex( row => row.data.slug  === this.id );
            },
            totalModels(){
                return this.filteredModels.length;
            },
            model() {
                return this.filteredModels.find( row => row.data.slug === this.id );
            },
            filteredModels() {
                const searchParams = this.$store.state.searchParams[this.slug] || {} ;
                return this.models.filter( ( model ) => {
                    return ( !searchParams.collection || searchParams.collection === 0 || searchParams.collection === model.data.collectionID ) &&
                        ( !searchParams.bedrooms || model.data.maxbedrooms >= searchParams.bedrooms || model.data.bedrooms >= searchParams.bedrooms ) &&
                        ( !searchParams.sqft || model.data.sqftAC >= searchParams.sqft );
                } );
            },
            models() {
                return this.$store.state.kiosk[this.slug].records;
            },
            communityID(){
                return this.$store.state.kiosk.communityID;
            },
            isOnline(){
                return this.$store.state.isOnline;
            }
        },
        methods:{
            iFrameLoaded(){
                setTimeout( () => {
                    this.$refs.iFrameLoader.classList.add( "off" );
                }, 500 );
            },
            formatNumber( value ){
                return new Intl.NumberFormat().format( value );
            },
            getNextModelSlug() {
                let nextIndex = this.modelIndex + 1;

                if ( nextIndex >= this.totalModels )
                    nextIndex = 0;

                return this.filteredModels[nextIndex].data.slug;
            },
            getPreviousModelSlug() {
                let prevIndex = this.modelIndex - 1;

                if ( prevIndex < 0 )
                    prevIndex = this.totalModels - 1;

                return this.filteredModels[prevIndex].data.slug;
            },
            openFancyBox( event ){
                event.preventDefault();

                const path = event.path || event.composedPath();
                let target;

                for ( let step of path ){
                    if ( step.tagName === "A" && step.dataset.goto ){
                        target = step;
                        break;
                    }
                }
                const fancybox = Fancybox.show( this.model.renderings,{
                    startIndex: target.dataset.goto,
                    Thumbs: {
                        showOnStart: false
                    },
                    Carousel: {
                        slidesPerPage: 1,
                        Navigation : false
                    }
                } );
            },
            openElevationVisualizer( event ){
                event.preventDefault();
                this.elevationVisualizer = !this.elevationVisualizer;
            },
            elevationsCarouselSetup() {
                if ( this.model.elevations.length ){
                    if ( !this.carousel ){
                        const slides = [];

                        this.model.elevations.forEach( ( elevation ) => {
                            slides.push( { html:`<img src="${elevation.src}" />`} );
                        } );

                        this.carousel = new Carousel( this.$refs.elevations, {
                            slides        : slides,
                            slidesPerPage : 1,
                            infinite      : true,
                            Navigation    : false
                        } );
                    }
                }
            },
            elevationsCarouselDestroy() {
                if ( this.carousel ){
                    this.carousel.destroy();
                    this.carousel = null;
                }
            },
            saveFavorite( event ){
                Favorites.saveFavorite( event );
            },
            setupView( setupCarousel ) {
                this.elevationsCarouselSetup();
                this.$refs.favoriteLink.classList.remove( "fade-out" );
                Favorites.checkFavorites( this.$refs.sidebar );
            },
            toggleFloorplan( event ){
                this.currentStaticIndex = parseInt( event.target.value );
            },
            toggleSidebar() {
                this.$refs.sidebar.parentElement.classList.toggle( "collapsed" );
            }
        },
        beforeRouteEnter( to, from, next ) {
            next( vm => {
                vm.setupView();
            } );
        },
        beforeRouteLeave( to,from ) {
            this.elevationVisualizer = false;
            this.elevationsCarouselDestroy();
            return true;
        },
        beforeRouteUpdate( to,from ) {
            // schedule elevationsCarouselSetup after view update
            if ( to.name === from.name && to.path !== from.path ){
                this.elevationVisualizer = false;
                this.elevationsCarouselDestroy();
                this.$refs.favoriteLink.classList.add( "fade-out" );
                setTimeout( () => {
                    this.setupView( true );
                }, 250 );
            }
        },
        mounted(){
            if ( !this.didEnter )
                this.setupView();
            document.getElementById( "app" ).classList.add( this.slug );
        },
        unmounted(){
            // makes sure to remove slug if leaving path
            // as router handles setting
            document.getElementById( "app" ).classList.remove( this.slug );
        }
    };
</script>
